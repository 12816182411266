<template>
  <div>
    <b-alert v-model="showAlert" :variant="`${this.submitError ? 'danger' : 'success'}`" dismissible>
      {{ response }}
    </b-alert>

    <div>
      <label for="publisher" class="input-control mr-3 mb-0">Publisher</label>
    </div>
    <select v-model="selectedPublisher" id="publisher" class="form-control" @change="getData()">
      <option :value="null">Select Publisher</option>
      <option
        v-for="publisher in publishers"
        :value="publisher.id"
        :key="publisher.id"
        >{{ publisher.name }}</option
      >
    </select>

    <div>
      <label for="ssp" class="input-control mr-3 mb-0">Bidder</label>
    </div>
    <select
      :disabled="!selectedPublisher"
      v-model="selectedBidder"
      @change="onBidderChange"
      class="form-control"
    >
      <option :value="null">Select Bidder</option>
      <option v-for="bidder in bidderNames" :value="bidder" :key="`option-${bidder}`"
        >{{ bidder }}
      </option>
    </select>

    <div v-if="loading" class="my-4 text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else-if="!selectedPublisher">
      <h5 class="m-4">
        Please select a publisher to edit its Ad Units
      </h5>
    </div>
    <div v-else>
      <vs-button size="small" class="m-2" color="primary" @click="newAdUnit()">
        New Ad Unit
      </vs-button>
      <vs-button size="small" color="primary" class="m-2" @click="generatePbConfig()">
        Generate pb_config
      </vs-button>
      <div class="disclaimer">
        * To delete an Ad Unit, leave its Code blank and submit.
      </div>

      <div class="flex justify-end m-4">
        <b-button :disabled="!formChanged" variant="primary" @click="onSubmit">Submit</b-button>
        <b-button :disabled="!formChanged" class="ml-4" variant="outline-primary" @click="onReset">Discard Changes</b-button>
      </div>

      <b-table :items="adUnits" :fields="tableHeaders" responsive="md" :tbody-tr-class="rowClass">
        <template #cell()="data">
          <component
            :is="dynamicFieldComponent(data.value.type)"
            :value="data.value"
          />
        </template>

        <template #cell(bids)="row">
          <b-button v-show="!selectedBidder" size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Hide' : 'Show'}} Bids
          </b-button>
        </template>

        <template v-slot:cell(duplicate)="row">
          <b-button
            v-show="!selectedBidder"
            class="btn-sm"
            pill
            @click="duplicateAdUnit(row.item, row.index)"
            variant="outline-primary"
          >
            <i class="fa fa-plus"></i>
          </b-button>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <b-row class="mb-2" v-for="bidder in filteredBids(row.item.bids)" :key="uniqKey(row, bidder)">
              <b-col>
                <b> {{ bidder.bidder }} </b>
              </b-col>
              <template v-for="(value, key) in bidder.params">
                <template>
                  <template v-if="key == 'isSSPActive'">
                    <template v-if="!bidder.params['isSSPActive']">(SSP disabled)</template>
                  </template>
                  <template v-else>
                    <b-col
                      class="text-md-right"
                      :key="`${uniqKey(row, bidder, key)}`"
                    >
                      {{ key }}:
                    </b-col>
                    <b-col md="2" :key="`${uniqKey(row, bidder, key)}-field`">
                      <component
                        :is="dynamicFieldComponent(value ? value.type : null)"
                        :value="value"
                        :bidder="bidder"
                        :isSSPActive="bidder.params['isSSPActive']"
                      />
                    </b-col>
                  </template>
                </template>
              </template>
            </b-row>
          </b-card>
          <hr class="separator"/>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { PUBLISHERS } from "../../../utils/product_entities";
import { dynamicFieldComponent } from "../../../utils/prebid_config";
import { getCircularReplacer } from "../../../utils/helpers"
import _ from 'lodash';

export default {
  data() {
    return {
      loading: false,
      formChanged: false,
      adUnits: [],
      tableHeaders: {},
      publishers: PUBLISHERS,
      selectedPublisher: null,
      selectedBidder: null,
      bidderNames: null,
      response: null,
      showAlert: false,
      submitError: false,
      dynamicFieldComponent: dynamicFieldComponent
    };
  },
  mounted() {
    const publisherId = this.$route.query.publisher_id;
    if (publisherId) {
      this.selectedPublisher = publisherId
      this.getData();
    }
    this.$eventHub.$on("fieldChanged", () => {
      this.formChanged = true;
    })
  },
  methods: {
    filteredBids(bids) {
      return this.selectedBidder ? bids.filter(b => b.bidder == this.selectedBidder) : bids;
    },
    getData() {
      if (!this.selectedPublisher) return

      this.loading = true;
      this.$http.get(`${this.host}/settings/prebid_ad_units_index?publisher_id=${this.selectedPublisher}`).then(res => {
        this.adUnits = res.data.ad_units;
        if (res.data.ad_units.length > 0)
          this.bidderNames = res.data.ad_units[0].bids.map(b => b.bidder);
        this.tableHeaders = Object.keys(res.data.schema)
                                  .map((header) => ({ key: header, label: header }))
                                  .concat([
                                    { key: "bids", label: "bids" },
                                    { key: "duplicate", label: "" },
                                  ]);
        this.loading = false;
      });
    },
    onBidderChange() {
      this.adUnits.map(au => au._showDetails = this.selectedBidder != null)
    },
    uniqKey(row, bidder, key) {
      let uniqKey = row.item.code.value;
      if (bidder) uniqKey += `-${bidder.bidder}`
      if (key)    uniqKey += `-${key}`
      return uniqKey
    },
    onSubmit() {
      this.showAlert = false;
      this.submitError = false;

      const adUnitsPayload = this.adUnits.map(au => {
        au.bids = au.bids.map(b => _.omit(b, "siblings", "checkbox"));
        return _.omit(au, "_showDetails", "animate");
      });
      this.$http
        .post(`${this.host}/settings/prebid_ad_units_update`, {
          publisher_id: this.selectedPublisher,
          ad_units: adUnitsPayload
        })
        .then(res => this.response = res.data.result)
        .catch(ex => { this.response = ex.response.data.error; this.submitError = true })
        .finally(() => { this.showAlert = true; this.formChanged = false });
    },
    onReset() {
      if (confirm("Are you sure you want to reset? All changes will be lost."))
        this.getData()
    },
    newAdUnit() {
      this.$router.push({
        name: "new-ad-unit",
        query: { publisher_id: this.selectedPublisher }
      });
    },
    duplicateAdUnit(adUnit, index) {
      const adUnitClone = JSON.parse(JSON.stringify(adUnit, getCircularReplacer()));
      adUnitClone.code.value = `COPY_${adUnit.code.value}`
      adUnitClone.animate = true
      this.adUnits.splice(index + 1, 0, adUnitClone);
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.animate) return 'dup-animate'
    },
    generatePbConfig() {
      this.showAlert = false;
      this.submitError = false;

      const publisherName = this.publishers.find(
        p => p.id === this.selectedPublisher
      ).name;
      if (
        !confirm(
          this.formChanged
            ? `⚠️⚠️⚠️ There are unsaved changes ⚠️⚠️⚠️\nDeploy monetization configuration for ${publisherName} anyway?`
            : `You are about to deploy a new monetization configuration for ${publisherName}, Continue?`
        )
      )
        return

      this.$http
        .post(`${this.host}/settings/prebid_generate_pb_config`, {
          publisher_id: this.selectedPublisher
        })
        .then(res => this.response = res.data.result)
        .catch(ex => { this.response = ex.response.data.submitError; this.submitError = true })
        .finally(() => this.showAlert = true);
    }
  }
};
</script>

<style scoped>
.disclaimer {
  margin: 0.5rem;
  font-style: italic;
  text-align: end;
}

.separator {
  height: 1px;
  border: none;
  color: #333;
  background-color: #333;
}
</style>

<style>
.dup-animate {
  animation-iteration-count: 1;
  animation: dup-animation 1s steps(20, start);
}

@keyframes dup-animation {
  0% {
    opacity: 0;
  }
  50% {
    background-color: lightblue;
  }
}
</style>
